import { LogInResponse } from '../../../_core/models/customer.models'
import {
  CheckSignUpStatus,
  CheckSignUpStatusResponse,
  ResetPasswordData,
  ResetPasswordResponse,
  SignUpData,
  SignUpResponse,
} from '../../../_core/models/fleet-customer.models'
import { createAction, props } from '@ngrx/store'
import { AuthState } from './auth.reducer'

export const authActions = {
  LOGIN: createAction(
    '[Login Page] Login',
    props<{
      payload: {
        email: string
        password: string
        trackingParams?: { invoiceNumber: string; invoiceIssuedForId: string }
      }
    }>()
  ),

  LOGIN_SUCCESS: createAction(
    '[Fleet Customer Api] Login Success',
    (response: LogInResponse) => ({ payload: response })
  ),

  LOGIN_ERROR: createAction(
    '[Fleet Customer Api] Login Error',
    (error?: { status: number; ok: boolean; message: string }) => ({
      payload: error,
    })
  ),

  LOGOUT: createAction('[App Header] Logout'),

  LOGOUT_SUCCESS: createAction('[App Header] Logout Success'),

  LOGOUT_ERROR: createAction('[App Header] Logout Error'),

  SEND_PASSWORD_RESET_EMAIL: createAction(
    '[Forgot Password Page] Send Reset Email',
    (email: string) => ({ payload: email })
  ),

  SEND_PASSWORD_RESET_EMAIL_SUCCESS: createAction(
    '[Customer Api] Send Password Reset Email Success'
  ),

  SEND_PASSWORD_RESET_EMAIL_ERROR: createAction(
    '[Customer Api] Send Password Reset Email Error',
    (message: string) => ({ payload: message })
  ),

  RESET_PASSWORD: createAction(
    '[Reset Password Page] Reset Password',
    (data: ResetPasswordData) => ({ payload: data })
  ),

  RESET_PASSWORD_SUCCESS: createAction(
    '[Fleet Customer Api] Reset Password Success',
    (response: ResetPasswordResponse) => ({ payload: response })
  ),

  RESET_PASSWORD_ERROR: createAction(
    '[Fleet Customer Api] Reset Password Error',
    (errorMessage?: string) => ({ payload: errorMessage })
  ),

  CHECK_SIGN_UP_STATUS: createAction(
    '[Sign Up Page] Get Sign Up Status',
    (data: CheckSignUpStatus) => ({ payload: data })
  ),

  CHECK_SIGN_UP_STATUS_SUCCESS: createAction(
    '[Fleet Customer Api] Get Sign Up Status Success',
    (response: CheckSignUpStatusResponse) => ({ payload: response })
  ),

  CHECK_SIGN_UP_STATUS_ERROR: createAction(
    '[Fleet Customer Api] Get Sign Up Status Error',
    (message?: string) => ({ payload: message })
  ),

  SIGN_UP: createAction('[Sign Up Page] Sign Up', (data: SignUpData) => ({
    payload: data,
  })),

  SIGN_UP_SUCCESS: createAction(
    '[Fleet Customer Api] Sign Up Success',
    (response: SignUpResponse, password) => ({
      payload: { response, password },
    })
  ),

  SIGN_UP_ERROR: createAction(
    '[Fleet Customer Api] Sign Up Error',
    (message?: string) => ({ payload: message })
  ),

  RESEND_INVITE_EMAIL: createAction(
    '[Sign Up Page] Resend Invite Email',
    (email: string) => ({ payload: email })
  ),

  RESEND_INVITE_EMAIL_SUCCESS: createAction(
    '[Fleet Customer Api] Resend Invite Email Success'
  ),

  RESEND_INVITE_EMAIL_ERROR: createAction(
    '[Fleet Customer Api] Resend Invite Email Error'
  ),

  CLEAR_STATE: createAction(
    '[Auth Pages] Clear State',
    (key: keyof AuthState) => ({ payload: key })
  ),
}
